import { Injectable } from '@angular/core';
import {
  CreateClassGQL,
  CreateClassInput,
  DeleteClassGQL,
  GetAllClassesGQL,
  UpdateClassGQL,
  UpdateClassInput,
} from "../../graphql/graphql";
import {catchError, map, Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";

@Injectable({
  providedIn: 'root'
})
export class ClassesClientService {

  constructor(
    private getAllClassesGQL: GetAllClassesGQL,
    private updateClassGQL: UpdateClassGQL,
    private createClassGQL: CreateClassGQL,
    private deleteClassGQL: DeleteClassGQL
  ) { }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    return this.getAllClassesGQL.fetch(undefined, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data?.class ?? [], executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: UpdateClassInput = {
      guid: id,
      setDescription: {value: userInput.description},
      setParentId: {value: userInput.parentId},
      setTypeOfLoss: {value: userInput.typeOfLoss}
    };
    return this.updateClassGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateClass, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: CreateClassInput = {
      description: item.description,
      parentId: item.parentId ? item.parentId : null,
      typeOfLoss: item.typeOfLoss
    }
    return this.createClassGQL.mutate({input: parsedInput}).pipe(
      map((res: any) => this.toPayload(res.data.createClass, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteClassGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(res.data.deleteClass, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }
  private parseItems(item: any) {
    if (item) {
      return {
        ...item,
        __id: item.guid
      }
    }
  }
}
