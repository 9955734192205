import {Injectable} from '@angular/core';
import {
  CreateCommentGQL,
  CreateCommentInput,
  DeleteCommentGQL,
  GetCommentsByEventGuidGQL,
  UpdateCommentGQL,
  UpdateCommentInput
} from "../../graphql/graphql";
import {BehaviorSubject, catchError, map, Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";

@Injectable({
  providedIn: 'root'
})
export class CommentsClientService {
  activeEventGuid = new BehaviorSubject<string>('');

  constructor(
    private getCommentsByEventGuidGQL: GetCommentsByEventGuidGQL,
    private updateCommentGQL: UpdateCommentGQL,
    private createCommentGQL: CreateCommentGQL,
    private deleteCommentGQL: DeleteCommentGQL
  ) {
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();

    return this.getCommentsByEventGuidGQL.fetch({input: this.activeEventGuid.value}, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data?.comment ?? [], executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      )
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: UpdateCommentInput = {
      guid: id,
      setDescription: {value: userInput?.description},
      setEventId: {value: userInput?.eventId}
    }
    return this.updateCommentGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateComment, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: CreateCommentInput = {
      description: item.description,
      eventId: this.activeEventGuid.value
    }
    return this.createCommentGQL.mutate({input: parsedInput}).pipe(
      map((res: any) => this.toPayload(res.data.createComment, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteCommentGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(res.data.deleteComment, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }

  private parseItems(item: any) {
    if (item && typeof item != "boolean") {
      return {
        ...item,
        __id: item.guid
      }
    }
    return item;
  }
}
