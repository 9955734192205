import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
  styleUrls: ['./reasons.component.scss']
})
export class ReasonsComponent {
  constructor(
    private router: Router
  ) {
  }

  goBack() {
    void this.router.navigate(['/'])
  }
}
