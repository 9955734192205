import {Component, Input} from '@angular/core';
import {AppStateService} from "@cat2/legacy-meta-cat";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @Input() hide = '';

  constructor(
    private appStateService: AppStateService
  ) {
  }

  closeOverlay() {
    this.appStateService.setShowOverlay(false);
  }
}
