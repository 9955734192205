import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommentsGridComponent} from './comments-grid.component';
import {Cat2DataGridModule} from "@cat2/legacy-meta-cat";


@NgModule({
  declarations: [
    CommentsGridComponent
  ],
  exports: [
    CommentsGridComponent
  ],
  imports: [
    CommonModule,
    Cat2DataGridModule
  ]
})
export class CommentsGridModule {
}
