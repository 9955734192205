import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ClassesClientService} from "../../core/services/classes-client.service";
import {
  AppStateService,
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  LegacyMetaCatYesNoDialogComponent,
  MakeSelectProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {GetAllClassesGQL, GetAllClassesQuery, Loss} from "../../graphql/graphql";
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {compareSelectOptions} from "../../core/helpers/sort-helper";


@Component({
  selector: 'app-classes-grid',
  templateUrl: './classes-grid.component.html',
  styleUrls: ['./classes-grid.component.scss']
})
export class ClassesGridComponent implements OnInit {

  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;
  TypeofLoss = [
    'PLANNED',
    'UNPLANNED_AVAILABILITY',
    'UNPLANNED_QUALITY',
    'UNPLANNED_SPEED'
  ]

  constructor(
    private _client: ClassesClientService,
    private _process: ClientProcessingService,
    private _dialog: MatDialog,
    private getAllClassesGQL: GetAllClassesGQL,
    private changeDetectorRef: ChangeDetectorRef,
    private appState: AppStateService
  ) {
  }

  ngOnInit(): void {
    this.appState.showLoadingOverlay();
    setTimeout(() => {
      this.reloadGrid();
      this.appState.hideLoadingOverlay();
      this.changeDetectorRef.detectChanges();
    }, 1000);
  }

  reloadGrid() {
    this.getAllClassesGQL.fetch(undefined, {fetchPolicy: "no-cache"})
      .subscribe((classes) => this.loadMetadata(classes.data));
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  loadMetadata(allClasses: GetAllClassesQuery) {
    const classesOptions: SelectOption[] = allClasses?.class.map(i => ({
      display: i.description,
      value: i.guid
    })).concat({display: '', value: ''}) ?? [];
    const typeOfLossOptions: SelectOption[] = this.TypeofLoss.map(i => ({
      display: i,
      value: i
    }));
    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('description', 'Description'),
        MakeSelectProperty('parentId', 'Parent', classesOptions.sort(compareSelectOptions)),
        MakeSelectProperty('typeOfLoss', 'Type Of Loss', typeOfLossOptions.sort(compareSelectOptions))
      ])
      //grid configuration
      .setGridAddMode('dialog')
      .setGridEditMode('none')
      .forceShowGridDeleteRow(false)
      .addGridTitle('')
      .setFocusedRowEnabled(false)
      //grid columns
      .addGridColumn('description')
      .addGridColumn('parentId')
      .addGridColumn('typeOfLoss')
      //form configuration
      .addFormTitle('New Class')
      .addPropertyToFormRow('description', 0)
      .addPropertyToFormRow('parentId', 0)
      .addPropertyToFormRow('typeOfLoss', 1)
      .makeFieldRequired('description')
      .makeFieldRequired('typeOfLoss')
      .create();
  }

  editRow(data: any) {
    this._dialog.open(ClassEditDialogComponent, {
      width: '600px',
      data: data
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        data = {
          ...data,
          id: result.id ?? "",
          description: result.description ?? "",
          parentId: result.parentId == "" ? null : result.parentId,
          typeOfLoss: result.typeOfLoss
        }
        this.dataSource?.update(data.id, data).then(() => this.reloadGrid());
      }
    });
  }

  deleteRow(data: any) {
    this._dialog.open(LegacyMetaCatYesNoDialogComponent, {
      data: {
        dialogTitle: 'Delete',
        dialogText: `Are you sure you want to delete this class ${data.description}?`
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.dataSource?.remove(data.guid).then(() => this.reloadGrid());
      }
    });
  }
}

interface ClassModel {
  id: string;
  description: string;
  typeOfLoss: Loss;
  parentId?: string | null;
}

interface editClassModel {
  __typename?: "Class";
  guid: string;
  description: string;
  parentId?: string | null;
  typeOfLoss: Loss
}

@Component({
  selector: 'app-class-edit-dialog',
  template: `
    <mat-dialog-content>
      <legacy-meta-cat-form
        *ngIf="dialogMetadata"
        [model]="model"
        [metadata]="dialogMetadata"
        (cancel)="cancel()"
        (submitForm)="submit($event)"
      ></legacy-meta-cat-form>
    </mat-dialog-content>
  `,
  styles: []
})
export class ClassEditDialogComponent implements OnInit {

  dialogMetadata?: ModelMetadata;
  allClasses?: GetAllClassesQuery;
  model?: ClassModel;
  TypeofLoss = [
    'PLANNED',
    'UNPLANNED_AVAILABILITY',
    'UNPLANNED_QUALITY',
    'UNPLANNED_SPEED'
  ]

  constructor(
    private getAllClassesGQL: GetAllClassesGQL,
    private _dialogRef: MatDialogRef<ClassEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
  }

  ngOnInit(): void {


    this.getAllClassesGQL.fetch(undefined, {fetchPolicy: "no-cache"})
      .subscribe((classes) => {
        this.allClasses = classes.data;
        this.dialogMetadata = this.loadDialogMetadata()
      });

    this.model = {
      id: this.data.__id ?? "",
      description: this.data.description ?? "",
      parentId: this.data.parentId ?? null,
      typeOfLoss: this.data.typeOfLoss
    }
  }

  submit(data: any) {
    this._dialogRef.close(data);
  }

  cancel() {
    this._dialogRef.close();
  }

  loadDialogMetadata() {
    let filteredClasses: editClassModel[] = [];
    if (this.allClasses) {
      filteredClasses = this.filterInvalidParents(this.data.__id, this.allClasses.class)
    }
    let classesOptions: SelectOption[] = filteredClasses.map(i => ({
      display: i.description,
      value: i.guid
    })) ?? [];
    classesOptions.push({display: "", value: ""});
    const typeOfLossOptions: SelectOption[] = this.TypeofLoss.map(i => ({
      display: i,
      value: i
    }));
    return new ModelMetadataBuilder()
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('description', 'Description'),
        MakeSelectProperty('parentId', 'Parent', classesOptions.sort(compareSelectOptions)),
        MakeSelectProperty('typeOfLoss', 'Type Of Loss', typeOfLossOptions.sort(compareSelectOptions))
      ])
      .addFormTitle('Edit Class')
      .addPropertyToFormRow('description', 0)
      .addPropertyToFormRow('parentId', 0)
      .addPropertyToFormRow('typeOfLoss', 1)
      .makeFieldRequired('description')
      .makeFieldRequired('typeOfLoss')
      .create();
  };


  filterInvalidParents(id: string, classList: editClassModel[]) {
    const validClasses = new Set<string>();
    const isValidClass = (checkClass: editClassModel): boolean => {
      if (checkClass.guid == id) {
        return false;
      }
      if (checkClass.parentId !== null) {
        const parent = classList.find(c => c.guid == checkClass.parentId);
        if (parent && !isValidClass(parent)) {
          return false;
        }
      }
      return true;
    };
    classList.forEach(c => {
      if (isValidClass(c)) {
        validClasses.add(c.guid);
      }
    })
    return classList.filter(c => validClasses.has(c.guid));
  };

}
