import {Component, OnInit} from '@angular/core';
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {CommentsClientService} from "../../core/services/comments-client.service";

@Component({
  selector: 'app-comments-grid',
  templateUrl: './comments-grid.component.html',
  styleUrls: ['./comments-grid.component.scss']
})
export class CommentsGridComponent implements OnInit {
  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;

  constructor(
    private _client: CommentsClientService,
    private _process: ClientProcessingService
  ) {
  }

  ngOnInit(): void {
    this._client.activeEventGuid.subscribe(() => {
      this.loadMetadata();
      this.loadDataSource();
    })
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  loadMetadata() {
    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('description', 'Description'),
        MakeStringProperty('eventId', 'Event Id'),
      ])
      // grid configuration
      .setGridAddMode('dialog')
      .setGridEditMode('form')
      .disableRowGrouping()
      .addGridTitle('Comments')
      .setFocusedRowEnabled(false)
      // grid columns
      .addGridColumn('description')
      // form configuration
      .addFormTitle('Comment')
      .addPropertyToFormRow('description', 0)
      .makeFieldRequired('description')
      .create();
  }
}

