import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, switchMap} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {
  CreateEventGQL,
  CreateEventInput,
  DeleteEventGQL,
  GetAllClassesGQL,
  GetAllClassesQuery,
  GetAllEventsGQL,
  UpdateEventGQL,
  UpdateEventInput
} from "../../graphql/graphql";

@Injectable({
  providedIn: 'root'
})
export class EventsClientService {
  allClasses?: GetAllClassesQuery;

  constructor(
    private getAllEventsGQL: GetAllEventsGQL,
    private updateEventGQL: UpdateEventGQL,
    private createEventGQL: CreateEventGQL,
    private deleteEventGQL: DeleteEventGQL,
    private getAllClassesGQL: GetAllClassesGQL
  ) {
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    return this.getAllClassesGQL.fetch(undefined, {fetchPolicy: "no-cache"}).pipe(
      switchMap(classes => {
        this.allClasses = classes.data;
        return this.getAllEventsGQL.fetch(undefined, {fetchPolicy: "no-cache"})
      })).pipe(
      map(res => this.toPayload(res.data?.event ?? [], executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord')
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: UpdateEventInput = {
      guid: id,
      setDuration: {value: userInput?.duration ? userInput.duration * 60 : 0},
      setSiteEntryId: {value: userInput?.siteEntryId},
      setReasonId: {value: userInput?.reason},
      setStart: {value: userInput?.start},
      setUserId: {value: userInput?.userId},
    };
    return this.updateEventGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateEvent, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: CreateEventInput = {
      duration: item.duration ? item.duration * 60 : 0,
      siteEntryId: item.siteEntryId,
      reasonId: item.reason,
      start: item.start,
      userId: "00000000-0000-0000-0000-000000000000",
    }
    return this.createEventGQL.mutate({input: parsedInput}).pipe(
      map((res: any) => this.toPayload(res.data.createEvent, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteEventGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(res.data.deleteEvent, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }

  private parseItems(item: any) {
    const itemClass = this.allClasses?.class.find(i => i.guid == item.reason?.classId);
    if (item) {
      return {
        ...item,
        __id: item.guid,
        reasonGuid: item.reason?.guid,
        reasonDescription: item.reason?.description,
        classDescription: itemClass?.description,
        type: itemClass?.typeOfLoss,
        duration: item.duration ? item.duration / 60 : undefined
      }
    }
  }
}
