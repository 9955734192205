import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {
  AppStateService,
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeDateProperty,
  MakeNumberProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {EventsClientService} from "../../core/services/events-client.service";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {combineLatest} from "rxjs";
import {GetAllClassesGQL, GetAllReasonsGQL, Loss, SiteEntriesGQL} from "../../graphql/graphql";
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {compareSelectOptions} from "../../core/helpers/sort-helper";
import {CommentsClientService} from "../../core/services/comments-client.service";
import {formatDate} from '@angular/common';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {CommentsGridComponent} from "../comments-grid/comments-grid.component";


@Component({
  selector: 'app-events-grid',
  templateUrl: './events-grid.component.html',
  styleUrls: ['./events-grid.component.scss']
})
export class EventsGridComponent implements OnInit {
  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;
  unselectRow = false;

  constructor(
    private _client: EventsClientService,
    private _process: ClientProcessingService,
    private appState: AppStateService,
    private _dialog: MatDialog,
    private commentsClientService: CommentsClientService,
    private changeDetectorRef: ChangeDetectorRef,
    private _bottomSheet: MatBottomSheet
  ) {
  }

  ngOnInit(): void {
    this.appState.showLoadingOverlay();
    setTimeout(() => {
      this.loadMetadata();
      this.loadDataSource();
    });
    setTimeout(() => {
      this.appState.hideLoadingOverlay();
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  onUnselectRow() {
    this.unselectRow = true;
    setTimeout(() => this.unselectRow = false);
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  loadMetadata() {
    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('id', 'ID'),
        MakeDateProperty('start', 'Start Date Time', 'datetime-local'),
        MakeNumberProperty('duration', 'Duration (minutes)'),
        MakeStringProperty('siteEntryId', 'Site Entry Id'),
        MakeStringProperty('classDescription', 'Class'),
        MakeStringProperty('reasonDescription', 'Reason'),
        MakeStringProperty('type', 'Type')
      ])
      // grid configuration
      .setGridAddMode('none')
      .setGridEditMode('none')
      .forceShowGridDeleteRow(true)
      .setFocusedRowEnabled(true)
      .addGridTitle('')
      // grid columns
      .addGridColumn('start')
      .addGridColumn('duration')
      .addGridColumn('siteEntryId')
      .addGridColumn('reasonDescription')
      .addGridColumn('classDescription')
      .addGridColumn('type')
      .create();
  }

  addEditRow(data: any, onEdit: boolean) {
    this._dialog.open(EditEventDialogComponent, {data: data, width: '600px'})
      .afterClosed().subscribe((result: any) => {
      if (result) {
        data = {
          ...data,
          id: result.id ?? "",
          start: result.start ?? new Date(),
          duration: result.duration ?? 0,
          siteEntryId: result.siteEntryId ?? "",
          class: result.class ?? "",
          reason: result.reason ?? "",
        };
        onEdit ?
          this.dataSource?.update(data.id, data).then(() => this.loadDataSource()) :
          this.dataSource?.insert(data).then(() => this.loadDataSource());
      }
      setTimeout(() => this.onUnselectRow(), 50);
    });
  }

  showComments(data: any) {
    this.commentsClientService.activeEventGuid.next(data.guid);
    const bottomSheetRef = this._bottomSheet.open(CommentsGridComponent, {panelClass: 'customBottomSheet'});
    bottomSheetRef.afterDismissed().subscribe(() => this.onUnselectRow());
  }
}


@Component({
  selector: 'app-event-edit-dialog.component',
  templateUrl: './event-edit-dialog.component.html',
  styleUrls: ['./events-grid.component.scss']
})
export class EditEventDialogComponent implements OnInit {
  allClasses?: SelectOption[];
  allReasons?: SelectOption[];
  allSiteEntries?: SelectOption[];
  date = new Date();

  form = this.fb.group({
    id: "",
    start: this.fb.control<string | null>(null, Validators.required),
    duration: [0, Validators.required],
    siteEntryId: ["", Validators.required],
    class: ["", Validators.required],
    reason: ["", Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private getAllClassesGQL: GetAllClassesGQL,
    private getAllReasonsGQL: GetAllReasonsGQL,
    private getAllSiteEntriesGQL: SiteEntriesGQL,
    private _dialogRef: MatDialogRef<EditEventDialogComponent>,
    private appStateService: AppStateService,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    this.appStateService.showLoadingOverlay();
  }

  ngOnInit(): void {
    combineLatest([
      this.getAllClassesGQL.watch(undefined, {fetchPolicy: "no-cache"}).valueChanges,
      this.getAllReasonsGQL.watch(undefined, {fetchPolicy: "no-cache"}).valueChanges,
      this.form.controls.class.valueChanges,
      this.getAllSiteEntriesGQL.watch(undefined, {fetchPolicy: "no-cache"}).valueChanges
    ]).subscribe(([classes, reasons, classValue, siteEntries]) => {
      // prepare classes display values
      this.allClasses = classes.data.class.map(i => ({
        display: i.description,
        value: i.guid
      })).sort(compareSelectOptions);

      // prepare reasons display values
      this.allReasons = reasons.data.reason
        .filter(r => r.classId === classValue)
        .map(r => ({
          display: r.description,
          value: r.guid
        })).sort(compareSelectOptions);
      const reason = this.form.controls.reason.value;

      // If there's no reason for a class, add error and mark as dirty
      if (reason && !this.allReasons.some(r => r.value === reason))
        this.form.patchValue({reason: null});
      if (this.form.controls.class.dirty && !this.allReasons.length)
        this.form.controls.reason.markAsDirty();

      // Add date validator if the class
      if (classes.data.class.some(c => c.guid === classValue && c.typeOfLoss !== Loss.Planned)) {
        this.form.controls.start.addValidators(this.dateValidator());
      } else {
        this.form.controls.start.removeValidators(this.dateValidator());
        this.form.controls.start.clearValidators();
      }
      this.form.controls.start.updateValueAndValidity();

      // prepare site entries display values
      this.allSiteEntries = siteEntries.data.siteEntries.map(i => ({
        display: i.description + ' - ' + i.id + ' - ' + i.siteEntryClass!.type,
        value: i.id
      })).sort(compareSelectOptions);
      this.appStateService.hideLoadingOverlay();
    });

    this.form.patchValue({
      id: this.data?.guid ?? "",
      start: this.data?.start ? formatDate(this.data.start, 'yyyy-MM-ddTHH:mm', 'en') : null,
      duration: this.data?.duration ?? 0,
      siteEntryId: this.data?.siteEntryId ?? "",
      class: this.data?.reason.classId ?? "",
      reason: this.data?.reason.guid ?? "",
    });
  }


  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const userDate: Date = control.value;
      if (userDate && new Date().getTime() < new Date(userDate).getTime()) {
        return {futureDate: true};
      }
      return null;
    };
  }

  submit() {
    if (this.form.valid && this.form.controls.start.value)
      this._dialogRef.close(({
        ...this.form.value,
        start: new Date(this.form.controls.start.value)
      }));
  }

  cancel() {
    this._dialogRef.close();
  }

  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'Required';
    }
    if (control.hasError('futureDate')) {
      return 'If the type of loss of the class is not planned, the start must be before now';
    }
    return 'Error';
  }
}
