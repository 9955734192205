<button class="absolute" mat-icon-button (click)="addEditRow(null, false)" matTooltip="Add Event">
  <mat-icon>add</mat-icon>
</button>
<legacy-meta-cat-data-grid
    *ngIf="dataSource && metadata"
    [dataSource]="dataSource"
    [metadata]="metadata"
    [padding]="14"
    [rowActionTemplate]="rowActions"
    [unselectRow]="unselectRow"
    (clickedDataOnNoneEdit)="showComments($event)"
    (afterUpdated)="loadDataSource()"
>
</legacy-meta-cat-data-grid>

<ng-template #rowActions let-data="data">
  <legacy-meta-cat-row-action
      [icon]="'edit'"
      (actionClicked)="addEditRow(data, true)"
  ></legacy-meta-cat-row-action>
</ng-template>
