<legacy-meta-cat-data-grid
  *ngIf="dataSource && metadata"
  [dataSource]="dataSource"
  [metadata]="metadata"
  [padding]="14"
  [rowActionTemplate]="rowActions"
  (afterUpdated)="reloadGrid()"
  (afterDeleted)="reloadGrid()"
  (afterInserted)="reloadGrid()"
></legacy-meta-cat-data-grid>

<ng-template #rowActions let-data="data">
  <legacy-meta-cat-row-action
    [icon]="'edit'"
    (actionClicked)="editRow(data)"
  ></legacy-meta-cat-row-action>
  <legacy-meta-cat-row-action
    *ngIf="data.reasons.length == 0"
    [icon]="'delete'"
    (actionClicked)="deleteRow(data)"
  ></legacy-meta-cat-row-action>
  <legacy-meta-cat-row-action
    *ngIf="data.reasons.length != 0"
    [icon]="'delete'"
    [ngStyle]="{'color':'darkgray'}"
    [matTooltip]="'Class has associated reasons.'"
  ></legacy-meta-cat-row-action>
</ng-template>
