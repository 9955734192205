<div class="row mt-3">
  <legacy-meta-cat-title-bar class="col"
                             [title]="'Classes'"
                             [showBackBtn]="true"
                             (goBack)="goBack()"
  ></legacy-meta-cat-title-bar>
  <app-navigation [hide]="'classes'" class="col-3 d-flex align-items-center justify-content-end"></app-navigation>
</div>
<app-classes-grid></app-classes-grid>

