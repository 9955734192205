import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EventsPageComponent} from "./pages/events-page/events-page.component";
import {ClassesComponent} from "./pages/classes/classes.component";
import {ReasonsComponent} from "./pages/reasons/reasons.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: EventsPageComponent
  },
  {path: 'classes', component: ClassesComponent},
  {path: 'reasons', component: ReasonsComponent},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
