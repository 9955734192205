import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Cat2DataGridModule, Cat2FormModule} from "@cat2/legacy-meta-cat";
import {ClassEditDialogComponent, ClassesGridComponent} from "./classes-grid.component";
import {MatTooltip} from "@angular/material/tooltip";
import {MatDialogContent} from "@angular/material/dialog";


@NgModule({
  declarations: [
    ClassesGridComponent,
    ClassEditDialogComponent
  ],
  exports: [
    ClassesGridComponent
  ],
  imports: [
    CommonModule,
    Cat2DataGridModule,
    Cat2FormModule,
    MatTooltip,
    MatDialogContent
  ]
})
export class ClassesGridModule {
}
