<legacy-meta-cat-data-grid
    *ngIf="dataSource && metadata"
    [dataSource]="dataSource"
    [metadata]="metadata"
    [padding]="14"
    (afterUpdated)="loadDataSource()"
    (afterDeleted)="loadDataSource()"
    (afterInserted)="loadDataSource()"
    [rowActionTemplate]="rowActions"
>
</legacy-meta-cat-data-grid>
<ng-template #rowActions let-data="data">
  <legacy-meta-cat-row-action
    *ngIf="data.events?.length == 0"
    [icon]="'delete'"
    (actionClicked)="deleteRow(data)"
  ></legacy-meta-cat-row-action>
  <legacy-meta-cat-row-action
    *ngIf="data.events?.length != 0"
    [icon]="'delete'"
    [ngStyle]="{'color':'darkgray'}"
    [matTooltip]="'Reason has associated events.'"
  ></legacy-meta-cat-row-action>
</ng-template>
