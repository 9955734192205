import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditEventDialogComponent, EventsGridComponent} from './events-grid.component';
import {Cat2DataGridModule, LegacyMetaCatDatePickerModule, LegacyMetaCatDialogModule} from "@cat2/legacy-meta-cat";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CommentsGridModule} from "../comments-grid/comments-grid.module";
import {MatDialogContent} from "@angular/material/dialog";


@NgModule({
  declarations: [
    EventsGridComponent,
    EditEventDialogComponent
  ],
  exports: [
    EventsGridComponent
  ],
    imports: [
        CommonModule,
        Cat2DataGridModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        LegacyMetaCatDatePickerModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        CommentsGridModule,
        LegacyMetaCatDialogModule,
        MatDialogContent
    ]
})
export class EventsGridModule {
}
