import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  API_OVERRIDE,
  BYPASS_CAT2_SPA_CONFIGURATION,
  Cat2AppShellModule,
  Cat2FormModule,
  MOCK_API
} from "@cat2/legacy-meta-cat";
import {environment} from "../environments/environment";
import {OAuthModule} from "angular-oauth2-oidc";
import {PagesModule} from "./pages/pages.module";
import {GraphQLModule} from "./graphql.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Cat2AppShellModule,
    OAuthModule.forRoot(),
    GraphQLModule,
    PagesModule,
    Cat2FormModule
  ],
  providers: [
    {provide: API_OVERRIDE, useValue: environment.url},
    {provide: MOCK_API, useValue: environment.mockApi},
    {provide: BYPASS_CAT2_SPA_CONFIGURATION, useValue: environment.bypassConfig},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
