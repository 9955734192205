import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventsPageComponent} from './events-page/events-page.component';
import {ClassesComponent} from './classes/classes.component';
import {ReasonsComponent} from './reasons/reasons.component';
import {TitleBarModule} from "@cat2/legacy-meta-cat";
import {MaterialModule} from "../shared/material/material.module";
import {RouterLink} from "@angular/router";
import {EventsGridModule} from "../features/events-grid/events-grid.module";
import {ReasonsGridModule} from "../features/reasons-grid/reasons-grid.module";
import {ClassesGridModule} from "../features/classes-grid/classes-grid.module";
import {NavigationModule} from "../features/navigation/navigation.module";


@NgModule({
  declarations: [
    EventsPageComponent,
    ClassesComponent,
    ReasonsComponent
  ],
  imports: [
    CommonModule,
    TitleBarModule,
    MaterialModule,
    RouterLink,
    EventsGridModule,
    ReasonsGridModule,
    ClassesGridModule,
    NavigationModule
  ]
})
export class PagesModule {
}
