import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: number; output: number; }
  UUID: { input: string; output: string; }
  /** The UnsignedLong scalar type represents a unsigned 64-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedLong: { input: number; output: number; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Class = {
  __typename?: 'Class';
  description: Scalars['String']['output'];
  guid: Scalars['UUID']['output'];
  parent?: Maybe<Class>;
  parentId?: Maybe<Scalars['UUID']['output']>;
  reasons: Array<Reason>;
  typeOfLoss: Loss;
};


export type ClassReasonsArgs = {
  order?: InputMaybe<Array<ReasonSortInput>>;
  where?: InputMaybe<ReasonFilterInput>;
};

export type ClassFilterInput = {
  and?: InputMaybe<Array<ClassFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClassFilterInput>>;
  parent?: InputMaybe<ClassFilterInput>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  reasons?: InputMaybe<ListFilterInputTypeOfReasonFilterInput>;
  typeOfLoss?: InputMaybe<LossOperationFilterInput>;
};

/** A segment of a collection. */
export type ClassPagedCollectionSegment = {
  __typename?: 'ClassPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Class>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ClassSortInput = {
  description?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<ClassSortInput>;
  parentId?: InputMaybe<SortEnumType>;
  typeOfLoss?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  description: Scalars['String']['output'];
  event: Event;
  eventId: Scalars['UUID']['output'];
  guid: Scalars['UUID']['output'];
};

export type CommentFilterInput = {
  and?: InputMaybe<Array<CommentFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CommentFilterInput>>;
};

/** A segment of a collection. */
export type CommentPagedCollectionSegment = {
  __typename?: 'CommentPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CommentSortInput = {
  description?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
};

export type ComparableFilterOfInt32Input = {
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqual?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Int']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqual?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  notIs?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateClassInput = {
  description: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['UUID']['input']>;
  typeOfLoss: Loss;
};

export type CreateCommentInput = {
  description: Scalars['String']['input'];
  eventId: Scalars['UUID']['input'];
};

export type CreateEventInput = {
  duration: Scalars['Long']['input'];
  reasonId: Scalars['UUID']['input'];
  siteEntryId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  userId: Scalars['String']['input'];
};

export type CreateReasonInput = {
  classId: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteClassInput = {
  guid: Scalars['UUID']['input'];
};

export type DeleteCommentInput = {
  guid: Scalars['UUID']['input'];
};

export type DeleteEventInput = {
  guid: Scalars['UUID']['input'];
};

export type DeleteReasonInput = {
  guid: Scalars['UUID']['input'];
};

export type DepartmentModel = {
  __typename?: 'DepartmentModel';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  spans: Array<DepartmentSpanModel>;
  startOfDaySeconds: Scalars['Long']['output'];
};

export type DepartmentSpanModel = {
  __typename?: 'DepartmentSpanModel';
  classification: SpanClassifications;
  description: Scalars['String']['output'];
  durationSeconds: Scalars['UnsignedLong']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  sequence: Scalars['Short']['output'];
  startOffsetSeconds: Scalars['UnsignedLong']['output'];
};

export type EqualityFilterOfGuidInput = {
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  notIs?: InputMaybe<Scalars['UUID']['input']>;
};

export type EqualityFilterOfSiteEntryClassTypeInput = {
  in?: InputMaybe<Array<SiteEntryClassType>>;
  is?: InputMaybe<SiteEntryClassType>;
  notIn?: InputMaybe<Array<SiteEntryClassType>>;
  notIs?: InputMaybe<SiteEntryClassType>;
};

export type Event = {
  __typename?: 'Event';
  comments: Array<Comment>;
  duration: Scalars['Long']['output'];
  guid: Scalars['UUID']['output'];
  reason: Reason;
  reasonId: Scalars['UUID']['output'];
  siteEntry?: Maybe<SiteEntryModel>;
  siteEntryId: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};


export type EventCommentsArgs = {
  order?: InputMaybe<Array<CommentSortInput>>;
  where?: InputMaybe<CommentFilterInput>;
};

export type EventFilterInput = {
  and?: InputMaybe<Array<EventFilterInput>>;
  comments?: InputMaybe<ListFilterInputTypeOfCommentFilterInput>;
  duration?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
  reason?: InputMaybe<ReasonFilterInput>;
  reasonId?: InputMaybe<UuidOperationFilterInput>;
  siteEntryId?: InputMaybe<StringOperationFilterInput>;
  start?: InputMaybe<DateTimeOperationFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
};

/** A segment of a collection. */
export type EventPagedCollectionSegment = {
  __typename?: 'EventPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Event>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EventSortInput = {
  duration?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<ReasonSortInput>;
  reasonId?: InputMaybe<SortEnumType>;
  siteEntryId?: InputMaybe<SortEnumType>;
  start?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type LineModel = {
  __typename?: 'LineModel';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  lineType: LineType;
  processStepCode: Scalars['String']['output'];
};

export enum LineType {
  Evisceration = 'EVISCERATION',
  Kill = 'KILL',
  Other = 'OTHER',
  Pack = 'PACK'
}

export type ListFilterInputTypeOfCommentFilterInput = {
  all?: InputMaybe<CommentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CommentFilterInput>;
  some?: InputMaybe<CommentFilterInput>;
};

export type ListFilterInputTypeOfEventFilterInput = {
  all?: InputMaybe<EventFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EventFilterInput>;
  some?: InputMaybe<EventFilterInput>;
};

export type ListFilterInputTypeOfReasonFilterInput = {
  all?: InputMaybe<ReasonFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ReasonFilterInput>;
  some?: InputMaybe<ReasonFilterInput>;
};

export type ListFilterOfSiteEntryModelFilterAndSiteEntryModelInput = {
  all?: InputMaybe<SiteEntryModelFilterInput>;
  any?: InputMaybe<SiteEntryModelFilterInput>;
};

export type ListFilterOfSiteTreeModelFilterAndSiteTreeModelInput = {
  all?: InputMaybe<SiteTreeModelFilterInput>;
  any?: InputMaybe<SiteTreeModelFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export enum Loss {
  Planned = 'PLANNED',
  UnplannedAvailability = 'UNPLANNED_AVAILABILITY',
  UnplannedQuality = 'UNPLANNED_QUALITY',
  UnplannedSpeed = 'UNPLANNED_SPEED'
}

export type LossOperationFilterInput = {
  eq?: InputMaybe<Loss>;
  in?: InputMaybe<Array<Loss>>;
  neq?: InputMaybe<Loss>;
  nin?: InputMaybe<Array<Loss>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createClass?: Maybe<Class>;
  createComment?: Maybe<Comment>;
  createEvent?: Maybe<Event>;
  createReason?: Maybe<Reason>;
  deleteClass?: Maybe<Class>;
  deleteComment?: Maybe<Comment>;
  deleteEvent?: Maybe<Event>;
  deleteReason?: Maybe<Reason>;
  updateClass?: Maybe<Class>;
  updateComment?: Maybe<Comment>;
  updateEvent?: Maybe<Event>;
  updateReason?: Maybe<Reason>;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateReasonArgs = {
  input: CreateReasonInput;
};


export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


export type MutationDeleteReasonArgs = {
  input: DeleteReasonInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateReasonArgs = {
  input: UpdateReasonInput;
};

export type NullableEqualityFilterOfGuidInput = {
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['UUID']['input']>;
  isSpecified?: Scalars['Boolean']['input'];
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  notIs?: InputMaybe<Scalars['UUID']['input']>;
  notIsSpecified?: Scalars['Boolean']['input'];
};

export type ProcessModel = {
  __typename?: 'ProcessModel';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  processStepCode: Scalars['String']['output'];
};

export type ProcessStepModel = {
  __typename?: 'ProcessStepModel';
  code: Scalars['String']['output'];
  departmentCode?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  lineCode?: Maybe<Scalars['String']['output']>;
  processStepCode: Scalars['String']['output'];
  processStepType: ProcessStepType;
};

export enum ProcessStepType {
  Counter = 'COUNTER',
  CounterTwo = 'COUNTER_TWO',
  InspectionStation = 'INSPECTION_STATION',
  Scale = 'SCALE',
  SubLine = 'SUB_LINE'
}

export type Query = {
  __typename?: 'Query';
  class: Array<Class>;
  classById?: Maybe<Class>;
  classPaged?: Maybe<ClassPagedCollectionSegment>;
  comment: Array<Comment>;
  commentById?: Maybe<Comment>;
  commentPaged?: Maybe<CommentPagedCollectionSegment>;
  event: Array<Event>;
  eventById?: Maybe<Event>;
  eventPaged?: Maybe<EventPagedCollectionSegment>;
  reason: Array<Reason>;
  reasonById?: Maybe<Reason>;
  reasonPaged?: Maybe<ReasonPagedCollectionSegment>;
  siteEntries: Array<SiteEntryModel>;
};


export type QueryClassArgs = {
  order?: InputMaybe<Array<ClassSortInput>>;
  where?: InputMaybe<ClassFilterInput>;
};


export type QueryClassByIdArgs = {
  guid: Scalars['UUID']['input'];
};


export type QueryClassPagedArgs = {
  order?: InputMaybe<Array<ClassSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClassFilterInput>;
};


export type QueryCommentArgs = {
  order?: InputMaybe<Array<CommentSortInput>>;
  where?: InputMaybe<CommentFilterInput>;
};


export type QueryCommentByIdArgs = {
  guid: Scalars['UUID']['input'];
};


export type QueryCommentPagedArgs = {
  order?: InputMaybe<Array<CommentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentFilterInput>;
};


export type QueryEventArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryEventByIdArgs = {
  guid: Scalars['UUID']['input'];
};


export type QueryEventPagedArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryReasonArgs = {
  order?: InputMaybe<Array<ReasonSortInput>>;
  where?: InputMaybe<ReasonFilterInput>;
};


export type QueryReasonByIdArgs = {
  guid: Scalars['UUID']['input'];
};


export type QueryReasonPagedArgs = {
  order?: InputMaybe<Array<ReasonSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReasonFilterInput>;
};


export type QuerySiteEntriesArgs = {
  filter?: InputMaybe<SiteEntryModelFilterInput>;
};

export type Reason = {
  __typename?: 'Reason';
  class: Class;
  classId: Scalars['UUID']['output'];
  description: Scalars['String']['output'];
  events: Array<Event>;
  guid: Scalars['UUID']['output'];
};


export type ReasonEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};

export type ReasonFilterInput = {
  and?: InputMaybe<Array<ReasonFilterInput>>;
  class?: InputMaybe<ClassFilterInput>;
  classId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  or?: InputMaybe<Array<ReasonFilterInput>>;
};

/** A segment of a collection. */
export type ReasonPagedCollectionSegment = {
  __typename?: 'ReasonPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Reason>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReasonSortInput = {
  class?: InputMaybe<ClassSortInput>;
  classId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
};

export type SetValueInputOfDateTimeInput = {
  value: Scalars['DateTime']['input'];
};

export type SetValueInputOfGuidInput = {
  value: Scalars['UUID']['input'];
};

export type SetValueInputOfInt64Input = {
  value: Scalars['Long']['input'];
};

export type SetValueInputOfLossInput = {
  value: Loss;
};

export type SetValueInputOfNullableOfGuidInput = {
  value?: InputMaybe<Scalars['UUID']['input']>;
};

export type SetValueInputOfStringInput = {
  value: Scalars['String']['input'];
};

export type SiteEntryClassModel = {
  __typename?: 'SiteEntryClassModel';
  description: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  siteEntries: Array<SiteEntryModel>;
  type: SiteEntryClassType;
};


export type SiteEntryClassModelSiteEntriesArgs = {
  filter?: InputMaybe<SiteEntryModelFilterInput>;
};

export type SiteEntryClassModelFilterInput = {
  and?: InputMaybe<Array<SiteEntryClassModelFilterInput>>;
  description?: InputMaybe<StringFilterInput>;
  key?: InputMaybe<EqualityFilterOfGuidInput>;
  or?: InputMaybe<Array<SiteEntryClassModelFilterInput>>;
  siteEntries?: InputMaybe<ListFilterOfSiteEntryModelFilterAndSiteEntryModelInput>;
  type?: InputMaybe<EqualityFilterOfSiteEntryClassTypeInput>;
};

export enum SiteEntryClassType {
  CounterStep = 'COUNTER_STEP',
  CounterTwoStep = 'COUNTER_TWO_STEP',
  Department = 'DEPARTMENT',
  DeviceStep = 'DEVICE_STEP',
  EviscerationLine = 'EVISCERATION_LINE',
  InspectionStationStep = 'INSPECTION_STATION_STEP',
  KillLine = 'KILL_LINE',
  OtherLine = 'OTHER_LINE',
  PackLine = 'PACK_LINE',
  PackLineStep = 'PACK_LINE_STEP',
  PackStationStep = 'PACK_STATION_STEP',
  Process = 'PROCESS',
  ScaleStep = 'SCALE_STEP',
  Site = 'SITE'
}

export type SiteEntryData = DepartmentModel | LineModel | ProcessModel | ProcessStepModel | SiteModel;

export type SiteEntryModel = {
  __typename?: 'SiteEntryModel';
  code: Scalars['String']['output'];
  data?: Maybe<SiteEntryData>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  siteEntryClass?: Maybe<SiteEntryClassModel>;
  siteEntryClassKey: Scalars['UUID']['output'];
  siteTrees: Array<SiteTreeModel>;
};


export type SiteEntryModelSiteTreesArgs = {
  filter?: InputMaybe<SiteTreeModelFilterInput>;
};

export type SiteEntryModelFilterInput = {
  and?: InputMaybe<Array<SiteEntryModelFilterInput>>;
  code?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  key?: InputMaybe<EqualityFilterOfGuidInput>;
  or?: InputMaybe<Array<SiteEntryModelFilterInput>>;
  siteEntryClass?: InputMaybe<SiteEntryClassModelFilterInput>;
  siteEntryClassKey?: InputMaybe<EqualityFilterOfGuidInput>;
  siteTrees?: InputMaybe<ListFilterOfSiteTreeModelFilterAndSiteTreeModelInput>;
};

export type SiteModel = {
  __typename?: 'SiteModel';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type SiteTreeModel = {
  __typename?: 'SiteTreeModel';
  children: Array<SiteTreeModel>;
  key: Scalars['UUID']['output'];
  parent?: Maybe<SiteTreeModel>;
  parentKey?: Maybe<Scalars['UUID']['output']>;
  sequence: Scalars['Int']['output'];
  siteEntry?: Maybe<SiteEntryModel>;
  siteEntryKey: Scalars['UUID']['output'];
  title: Scalars['String']['output'];
};


export type SiteTreeModelChildrenArgs = {
  filter?: InputMaybe<SiteTreeModelFilterInput>;
};

export type SiteTreeModelFilterInput = {
  and?: InputMaybe<Array<SiteTreeModelFilterInput>>;
  children?: InputMaybe<ListFilterOfSiteTreeModelFilterAndSiteTreeModelInput>;
  key?: InputMaybe<EqualityFilterOfGuidInput>;
  or?: InputMaybe<Array<SiteTreeModelFilterInput>>;
  parent?: InputMaybe<SiteTreeModelFilterInput>;
  parentKey?: InputMaybe<NullableEqualityFilterOfGuidInput>;
  sequence?: InputMaybe<ComparableFilterOfInt32Input>;
  siteEntry?: InputMaybe<SiteEntryModelFilterInput>;
  siteEntryKey?: InputMaybe<EqualityFilterOfGuidInput>;
  title?: InputMaybe<StringFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SpanClassifications {
  Exclusion = 'EXCLUSION',
  Shift = 'SHIFT'
}

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notIs?: InputMaybe<Scalars['String']['input']>;
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClassInput = {
  guid: Scalars['UUID']['input'];
  setDescription?: InputMaybe<SetValueInputOfStringInput>;
  setParentId?: InputMaybe<SetValueInputOfNullableOfGuidInput>;
  setTypeOfLoss?: InputMaybe<SetValueInputOfLossInput>;
};

export type UpdateCommentInput = {
  guid: Scalars['UUID']['input'];
  setDescription?: InputMaybe<SetValueInputOfStringInput>;
  setEventId?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateEventInput = {
  guid: Scalars['UUID']['input'];
  setDuration?: InputMaybe<SetValueInputOfInt64Input>;
  setReasonId?: InputMaybe<SetValueInputOfGuidInput>;
  setSiteEntryId?: InputMaybe<SetValueInputOfStringInput>;
  setStart?: InputMaybe<SetValueInputOfDateTimeInput>;
  setUserId?: InputMaybe<SetValueInputOfStringInput>;
};

export type UpdateReasonInput = {
  guid: Scalars['UUID']['input'];
  setClassId?: InputMaybe<SetValueInputOfGuidInput>;
  setDescription?: InputMaybe<SetValueInputOfStringInput>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateClassMutationVariables = Exact<{
  input: CreateClassInput;
}>;


export type CreateClassMutation = { __typename?: 'Mutation', createClass?: { __typename?: 'Class', description: string, guid: string, parentId?: string | null, typeOfLoss: Loss } | null };

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment?: { __typename?: 'Comment', guid: string, description: string } | null };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'Event', guid: string, userId: string, start: string, duration: number, reasonId: string, siteEntryId: string } | null };

export type CreateReasonMutationVariables = Exact<{
  input: CreateReasonInput;
}>;


export type CreateReasonMutation = { __typename?: 'Mutation', createReason?: { __typename?: 'Reason', description: string, guid: string, classId: string } | null };

export type DeleteClassMutationVariables = Exact<{
  input: DeleteClassInput;
}>;


export type DeleteClassMutation = { __typename?: 'Mutation', deleteClass?: { __typename?: 'Class', guid: string, description: string } | null };

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: { __typename?: 'Comment', guid: string } | null };

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent?: { __typename?: 'Event', guid: string } | null };

export type DeleteReasonMutationVariables = Exact<{
  input: DeleteReasonInput;
}>;


export type DeleteReasonMutation = { __typename?: 'Mutation', deleteReason?: { __typename?: 'Reason', guid: string } | null };

export type GetAllClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllClassesQuery = { __typename?: 'Query', class: Array<{ __typename?: 'Class', guid: string, description: string, parentId?: string | null, typeOfLoss: Loss, reasons: Array<{ __typename?: 'Reason', guid: string }> }> };

export type GetAllEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEventsQuery = { __typename?: 'Query', event: Array<{ __typename?: 'Event', guid: string, userId: string, start: string, duration: number, siteEntryId: string, reason: { __typename?: 'Reason', guid: string, classId: string, description: string } }> };

export type GetAllReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllReasonsQuery = { __typename?: 'Query', reason: Array<{ __typename?: 'Reason', guid: string, description: string, classId: string, events: Array<{ __typename?: 'Event', guid: string }> }> };

export type SiteEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SiteEntriesQuery = { __typename?: 'Query', siteEntries: Array<{ __typename?: 'SiteEntryModel', key: string, id: string, code: string, description: string, siteEntryClassKey: string, siteEntryClass?: { __typename?: 'SiteEntryClassModel', key: string, type: SiteEntryClassType, description: string } | null, siteTrees: Array<{ __typename?: 'SiteTreeModel', key: string, title: string, sequence: number, siteEntryKey: string }> }> };

export type GetCommentsByEventGuidQueryVariables = Exact<{
  input: Scalars['UUID']['input'];
}>;


export type GetCommentsByEventGuidQuery = { __typename?: 'Query', comment: Array<{ __typename?: 'Comment', guid: string, description: string, eventId: string }> };

export type UpdateClassMutationVariables = Exact<{
  input: UpdateClassInput;
}>;


export type UpdateClassMutation = { __typename?: 'Mutation', updateClass?: { __typename?: 'Class', guid: string, description: string, typeOfLoss: Loss, parentId?: string | null } | null };

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment?: { __typename?: 'Comment', guid: string, description: string, eventId: string } | null };

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'Event', guid: string, userId: string, start: string, duration: number, reasonId: string } | null };

export type UpdateReasonMutationVariables = Exact<{
  input: UpdateReasonInput;
}>;


export type UpdateReasonMutation = { __typename?: 'Mutation', updateReason?: { __typename?: 'Reason', description: string, guid: string, classId: string } | null };

export const CreateClassDocument = gql`
    mutation createClass($input: CreateClassInput!) {
  createClass(input: $input) {
    description
    guid
    parentId
    typeOfLoss
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClassGQL extends Apollo.Mutation<CreateClassMutation, CreateClassMutationVariables> {
    override document = CreateClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    guid
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommentGQL extends Apollo.Mutation<CreateCommentMutation, CreateCommentMutationVariables> {
    override document = CreateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    guid
    userId
    start
    duration
    reasonId
    siteEntryId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEventGQL extends Apollo.Mutation<CreateEventMutation, CreateEventMutationVariables> {
    override document = CreateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReasonDocument = gql`
    mutation CreateReason($input: CreateReasonInput!) {
  createReason(input: $input) {
    description
    guid
    classId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReasonGQL extends Apollo.Mutation<CreateReasonMutation, CreateReasonMutationVariables> {
    override document = CreateReasonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteClassDocument = gql`
    mutation DeleteClass($input: DeleteClassInput!) {
  deleteClass(input: $input) {
    guid
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteClassGQL extends Apollo.Mutation<DeleteClassMutation, DeleteClassMutationVariables> {
    override document = DeleteClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCommentDocument = gql`
    mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    guid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    override document = DeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEventDocument = gql`
    mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    guid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEventGQL extends Apollo.Mutation<DeleteEventMutation, DeleteEventMutationVariables> {
    override document = DeleteEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReasonDocument = gql`
    mutation DeleteReason($input: DeleteReasonInput!) {
  deleteReason(input: $input) {
    guid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReasonGQL extends Apollo.Mutation<DeleteReasonMutation, DeleteReasonMutationVariables> {
    override document = DeleteReasonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllClassesDocument = gql`
    query GetAllClasses {
  class {
    guid
    description
    parentId
    typeOfLoss
    reasons {
      guid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllClassesGQL extends Apollo.Query<GetAllClassesQuery, GetAllClassesQueryVariables> {
    override document = GetAllClassesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllEventsDocument = gql`
    query GetAllEvents {
  event {
    guid
    userId
    start
    duration
    reason {
      guid
      classId
      description
    }
    siteEntryId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllEventsGQL extends Apollo.Query<GetAllEventsQuery, GetAllEventsQueryVariables> {
    override document = GetAllEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllReasonsDocument = gql`
    query GetAllReasons {
  reason {
    guid
    description
    classId
    events {
      guid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllReasonsGQL extends Apollo.Query<GetAllReasonsQuery, GetAllReasonsQueryVariables> {
    override document = GetAllReasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SiteEntriesDocument = gql`
    query siteEntries {
  siteEntries {
    key
    id
    code
    description
    siteEntryClassKey
    siteEntryClass {
      key
      type
      description
    }
    siteTrees {
      key
      title
      sequence
      siteEntryKey
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SiteEntriesGQL extends Apollo.Query<SiteEntriesQuery, SiteEntriesQueryVariables> {
    override document = SiteEntriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCommentsByEventGuidDocument = gql`
    query GetCommentsByEventGuid($input: UUID!) {
  comment(where: {eventId: {eq: $input}}) {
    guid
    description
    eventId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCommentsByEventGuidGQL extends Apollo.Query<GetCommentsByEventGuidQuery, GetCommentsByEventGuidQueryVariables> {
    override document = GetCommentsByEventGuidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClassDocument = gql`
    mutation UpdateClass($input: UpdateClassInput!) {
  updateClass(input: $input) {
    guid
    description
    typeOfLoss
    parentId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClassGQL extends Apollo.Mutation<UpdateClassMutation, UpdateClassMutationVariables> {
    override document = UpdateClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    guid
    description
    eventId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCommentGQL extends Apollo.Mutation<UpdateCommentMutation, UpdateCommentMutationVariables> {
    override document = UpdateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    guid
    userId
    start
    duration
    reasonId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
    override document = UpdateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReasonDocument = gql`
    mutation UpdateReason($input: UpdateReasonInput!) {
  updateReason(input: $input) {
    description
    guid
    classId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReasonGQL extends Apollo.Mutation<UpdateReasonMutation, UpdateReasonMutationVariables> {
    override document = UpdateReasonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }