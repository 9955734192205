import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {GetAllClassesGQL, GetAllClassesQuery} from "../../graphql/graphql";
import {
  AppStateService,
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  LegacyMetaCatYesNoDialogComponent,
  MakeSelectProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {ReasonsClientService} from "../../core/services/reasons-client.service";
import {compareSelectOptions} from "../../core/helpers/sort-helper";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-reasons-grid',
  templateUrl: './reasons-grid.component.html',
  styleUrls: ['./reasons-grid.component.scss']
})
export class ReasonsGridComponent implements OnInit {
  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;

  constructor(
    private _client: ReasonsClientService,
    private _process: ClientProcessingService,
    private _dialog: MatDialog,
    private getAllClassesGQL: GetAllClassesGQL,
    private changeDetectorRef: ChangeDetectorRef,
    private appState: AppStateService
  ) {
  }

  ngOnInit(): void {
    this.appState.showLoadingOverlay();
    setTimeout(() => {
      this.getAllClassesGQL.watch(undefined, {fetchPolicy: "no-cache"}).valueChanges
        .subscribe((classes) => this.loadMetadata(classes.data));
      this.loadDataSource();
      this.appState.hideLoadingOverlay();
      this.changeDetectorRef.detectChanges();
    },1000);
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  loadMetadata(allClasses: GetAllClassesQuery) {
    const classesOptions: SelectOption[] = allClasses.class.map(i => ({
      display: i.description,
      value: i.guid
    })).sort(compareSelectOptions);

    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('description', 'Description'),
        MakeSelectProperty('class', 'Class', classesOptions)
      ])
      // grid configuration
      .setGridAddMode('dialog')
      .setGridEditMode('form')
      .forceShowGridDeleteRow(false)
      .disableGridDeleteRow(true)
      .addGridTitle('')
      .setFocusedRowEnabled(false)
      // grid columns
      .addGridColumn('description')
      .addGridColumn('class')
      // form configuration
      .addFormTitle('Reason')
      .addPropertyToFormRow('description', 0)
      .addPropertyToFormRow('class', 1)
      .makeFieldRequired('description')
      .makeFieldRequired('class')
      .create();
  }

  deleteRow(data: any){
    this._dialog.open(LegacyMetaCatYesNoDialogComponent, {
      data:{
        dialogTitle: 'Delete',
        dialogText: `Are you sure you want to delete this reason ${data.description}?`
      }
    }).afterClosed().subscribe((result: any) => {
      if(result) {
        this.dataSource?.remove(data.guid).then(() => this.loadDataSource());
      }
    });
  }
}
