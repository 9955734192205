import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";

export function compareSelectOptions(a: SelectOption, b: SelectOption) {
  if (a.display < b.display) {
    return -1;
  }
  if (a.display > b.display) {
    return 1;
  }
  return 0;
}
