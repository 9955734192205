import {Injectable} from '@angular/core';
import {catchError, map, Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {
  CreateReasonGQL,
  CreateReasonInput,
  DeleteReasonGQL,
  GetAllClassesGQL,
  GetAllClassesQuery,
  GetAllReasonsGQL,
  UpdateReasonGQL,
  UpdateReasonInput
} from "../../graphql/graphql";

@Injectable({
  providedIn: 'root'
})
export class ReasonsClientService {
  allClasses?: GetAllClassesQuery;

  constructor(
    private getAllClassesGQL: GetAllClassesGQL,
    private getAllReasonsGQL: GetAllReasonsGQL,
    private createReasonGQL: CreateReasonGQL,
    private updateReasonGQL: UpdateReasonGQL,
    private deleteReasonGQL: DeleteReasonGQL
  ) {
    this.getAllClassesGQL.watch().valueChanges.subscribe(classes => this.allClasses = classes.data);
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    return this.getAllReasonsGQL.fetch(undefined, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data?.reason ?? [], executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord');
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: UpdateReasonInput = {
      guid: id,
      setClassId: {value: userInput.class},
      setDescription: {value: userInput.description}
    };
    return this.updateReasonGQL.mutate({input: parsedInput})
      .pipe(
        map((res: any) => this.toPayload(res.data.updateEvent, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString()))
        ));
  }

  createClass(item: any): Observable<Payload<any>> {
    const executionStart = new Date();
    const parsedInput: CreateReasonInput = {
      classId: item.class,
      description: item.description
    };
    return this.createReasonGQL.mutate({input: parsedInput}).pipe(
      map((res: any) => this.toPayload(res.data.createReason, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  deleteRecord(id: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteReasonGQL.mutate({input: {guid: id}}).pipe(
      map((res: any) => this.toPayload(res.data.deleteEvent, executionStart)),
      catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
    );
  }

  private toPayload(items: any, executionStart: Date, errorMsg?: string): Payload<any> {
    return {
      data: Array.isArray(items) ? items.map(i => this.parseItems(i)) : this.parseItems(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    };
  }

  private parseItems(item: any) {
    if (item) {
      return {
        ...item,
        __id: item.guid,
        class: item.classId
      };
    }
  }
}
