import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReasonsGridComponent} from './reasons-grid.component';
import {Cat2DataGridModule} from "@cat2/legacy-meta-cat";
import {MatTooltip} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    ReasonsGridComponent
  ],
  exports: [
    ReasonsGridComponent
  ],
    imports: [
        CommonModule,
        Cat2DataGridModule,
        MatTooltip
    ]
})
export class ReasonsGridModule {
}
